import cloneDeep from 'lodash/cloneDeep';
import { Layouts, Images } from '@/components/ui/charts/plotly/PlotlyHelpers';

// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';

export default {
  name: 'ContactPointChart',

  extends: ChartMiddlewareVue,

  data() {
    return {
      adjustRanges: false,
      chartType: ChartTypeEnum.CONTACT_POINT.type,
      plotlyLayoutExtended: cloneDeep(Layouts.contactPoint),
    };
  },

  methods: {
    customMapping(converted) {
      this.plotlyData = converted.map(this.mapDefaultTraceSettings);

      let symbol = this.unitSymbolX(this.unitSystem.system);
      
      const image = Images.baseballPlateBG;
      const convertedImage = {
        ...image,
        sizex: this.convertDistance(image.sizex),
        sizey: this.convertDistance(image.sizey),
      }
      const xMaxRange = this.convertDistance(30);
      const xMinRange = this.convertDistance(-30);
      const yMaxRange = this.convertDistance(40);
      const yMinRange = this.convertDistance(-20);

      this.plotlyLayoutExtended.images = [convertedImage];
      this.plotlyLayoutExtended.xaxis = {
        ...this.plotlyLayoutExtended.xaxis,
        dtick: this.convertDistance(3),
        range: [xMinRange, xMaxRange],
        zeroline: false,
        showgrid: false,
        title: `Point of Contact Side (${symbol})`
      }

      this.plotlyLayoutExtended.yaxis = {
        ...this.plotlyLayoutExtended.yaxis,
        dtick: this.convertDistance(3),
        range: [yMinRange, yMaxRange],
        title: `Point of Contact Depth (${symbol})`,
      }

      this.plotlyLayoutExtended.colorway = this.getColorsFromSeries(this.plotlyData);

      if (this.hoverTextFormatter) {
        this.hoverTextFormatter(this.plotlyData);
      }
    },
    convertDistance(value) {
      return this.isMetric ? Math.floor(value) * 2.54 : value;
    }
  },
};
